@import '../../../../_variables.scss';

$form-width: 87%; // Default width of form given by Salesforce CSS
$input-vertical-margin: 24px;

div.modalContainer.layout-docked.embeddedServiceSidebar {
  * {
    font-family: $font-family !important;
    box-sizing: border-box !important;
  }

  // Chat window
  .dockableContainer {
    text-align: left !important;

    // Chat window body
    .sidebarBody {
      overflow-y: scroll;

      // Chat window body content
      .cMoneyBadger_PortalMobile_PreChatCmp {
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;

        br {
          display: none; // Handle margins manually
        }

        // Form submit error message, such as when user doesn't fill required field
        > div:first-of-type > span {
          display: block;
          margin: 0 auto;
          width: $form-width;
        }
      }
    }
  }

  // Form input headers
  .slds-form-element {
    margin-bottom: $input-vertical-margin;

    // Form input label
    .slds-form-element__label {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.4px;
      color: #30343b;
      line-height: 21px;
      margin-bottom: 3px;

      // Asterisk indicating required field
      .slds-required {
        margin-right: 3px;
        text-decoration: none;
      }
    }

    // Form inputs
    .slds-select,
    .slds-textarea {
      width: 100% !important;
      padding: 12px 16px;
      border-color: #c9cdd4 !important;
      color: #30343b !important;
    }

    // Help text underneath input
    .slds-form-element__help {
      color: red;
      font-size: 12px;
      margin-top: 6px;
    }
  }

  // Start chat button container
  .startButtonWrapper {
    display: block;
    margin: 0 auto;
    width: $form-width;

    // Start chat button
    .startButton {
      display: block;
      width: 100%;
      background-color: #4789f0 !important;
      border-radius: 8px;
      border: none;
      outline: none;
      color: white !important;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      padding: 12px 16px;
      height: 48px;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        background-color: #396ec0 !important;
      }
    }
  }

  &.sidebarMinimized {
    > button > div > div > div.minimizedImage > span {
      display: none;
    }
    > div > div > div.activeFeature.hideWhileLoading > div > div > h2 {
      margin-bottom: 0;
      > b {
        color: #1c3760;
      }
    }
  }

  &.sidebarMaximized {
    > div > div > div.activeFeature.hideWhileLoading > div > div {
      > div.endChatContainer
        > button.endChatButton.closeChatButton.uiButton--default.uiButton.embeddedServiceSidebarButton,
      > div
        > div.dialogButtonContainer
        > button.dialogButton.dialog-button-0.uiButton.embeddedServiceSidebarButton {
        background: #f04747 !important;
      }
    }
  }

  .sidebarHeader[embeddedService-chatHeader_chatHeader] {
    background: #1c3760 !important;
  }

  .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent {
    &.chasitor {
      background: #4789f0 !important;
      color: #ffffff !important;
    }

    &.agent {
      background: #c9cdd4 !important;
      color: #30343b !important;
    }
  }

  #dialogTextTitle {
    color: #f04747 !important;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
  }

  .slds-scrollable_y.divClass {
    height: 360px !important;
  }

  div.minimizedQueuePosition > span {
    display: none !important;
  }

  #input-9,
  #input-10,
  #input-30,
  #input-20 {
    caret-color: #4789f0;
    outline-color: #4789f0;
    color: #30343b;
  }

  #input-10 {
    border: solid 1px rgb(118, 118, 118) !important;
  }

  embeddedservice-chat-header > header > img {
    display: none;
  }

  #dialogTextTitle {
    margin-bottom: 30px !important;
  }

  .waitingGreeting {
    margin-bottom: 20px !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI {
    &.helpButton {
      min-width: 80px !important;
      min-height: 80px !important;
      width: 80px !important;
      height: 80px !important;
      background: #4789f0 !important;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
      border-radius: 50px !important;
      padding: 0;
      margin-right: 26px;
      margin-bottom: 80px;
    }

    &.sidebarHeader {
      min-width: 80px !important;
      min-height: 80px !important;
      width: 80px !important;
      height: 80px !important;
      background: #4789f0 !important;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
      border-radius: 50px !important;
      padding: 0;
      margin-right: 26px;
      margin-bottom: 80px;
    }

    &:not(.waiting) .messageContent {
      justify-content: center;
      margin-top: 27px !important;
      margin-left: 16px !important;

      background: url(../../icons/Vector.svg);
      background-repeat: no-repeat;
    }

    .minimizedText {
      background-color: #ef0c0c;
      position: fixed;
      left: auto;
      bottom: 60px;
      right: 14px;

      border-radius: 50px;
      height: 20px;
      width: 20px;
      z-index: 9000;

      color: #ffffff;
      font-size: 10px;
      overflow: hidden;
      text-overflow: inherit;

      align-items: start !important;
      display: inline;
      margin-right: 26px;
      margin-bottom: 80px;

      > .message {
        line-height: 1.3;
        white-space: normal !important;
      }
    }

    .embeddedServiceIcon {
      display: none !important;
    }

    .embeddedServiceLoadingBalls {
      display: none !important;
    }
  }
}
