@import '_variables.scss';

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-moz-document url-prefix() {
  /* Add this class to some element with custom styling
  in order to apply the changes to Mozilla Firefox 
  (for example ::before and ::after pseudo elements
  are not appearing in mozilla and this is enabling them) */
  .mozilla-specific {
    -moz-appearance: initial;
  }
}
