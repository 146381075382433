.MuiFormControl-marginNormal {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  margin-left: 10px !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.MuiSvgIcon-root {
  fill: black !important;
}

.MuiPickersDay-daySelected {
  background-color: #798393 !important;
}

.MuiPickersDay-current {
  color: #798393 !important;
}

.MuiPickersDay-daySelected {
  color: #fff !important;
}

.MuiFormHelperText-root.Mui-error {
  display: none !important;
}
